/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;1,100&family=Poppins:wght@100;300;500&family=Rajdhani:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;700;900&display=swap'); */

@font-face {
  font-family: Iowa;
  src: url('./assets/fonts/iowa.otf');
}

@font-face {
  font-family: HelveticaNeue;
  src: url('./assets/fonts/HelveticaNeue-Black.otf');
  font-weight: 900;
}
@font-face {
  font-family: HelveticaNeue;
  src: url('./assets/fonts/HelveticaNeue-Condensed.otf');
  font-weight: 800;
}
@font-face {
  font-family: HelveticaNeue;
  src: url('./assets/fonts/HelveticaNeue-Heavy.otf');
  font-weight: 700;
}
@font-face {
  font-family: HelveticaNeue;
  src: url('./assets/fonts/HelveticaNeue-Light.otf');
  font-weight: 400;
}
@font-face {
  font-family: HelveticaNeue;
  src: url('./assets/fonts/HelveticaNeue-Medium.otf');
  font-weight: 500;
}
@font-face {
  font-family: HelveticaNeue;
  src: url('./assets/fonts/HelveticaNeue-Thin.otf');
  font-weight: 300;
}

body {
  font-family: 'Fira Sans', sans-serif;
  /* font-family: 'Abegnale'; */
  font-weight: 300;
  margin: 0;

  color: #181818;
  /* background: linear-gradient(173.01deg, #FCEEE3 1.04%, #FFFEFC 1.05%, #FCE4DF 121.8%); */
  /* background: url(https://assets.getpartiful.com/backgrounds/aquamarine/web.jpg), linear-gradient(to right top, rgb(127, 221, 221) 0%, 22%, rgb(192, 227, 227) 30%, 50%, rgb(134, 206, 223) 60%, 75%, rgb(105, 154, 235) 92%); */
  background-color: #EFE9E9;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EFE9E9;
}

.gm-style-cc { display:none; }

.no-scroll {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.no-scroll::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}